import styled from "styled-components";

export const CityFilterStyled = styled.div`
    
    border-top: 1px solid #F0F0F0;
    padding: 16px 0;
    
    h2 {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        padding-left: 16px;
    }
    
    .search-input {
        //width: 268px;
        height: 32px;
        border-radius: 6px;
        border: 1px solid #D9D9D9;
    }
    
.cities-list-container {
    max-height: 160px;
    overflow-y: scroll;
    margin-top: 12px;
    
    .city-container {
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 16px;
        cursor: pointer;
        
        input {
            height: 16px;
            width: 16px;
        }
        
        span {
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            text-align: left;
            margin-left: 8px;
        }
    }

    .city-container:has(input:checked) {
        background-color: #E6F7FF;
        color:#1890FF
    }
}

`