import styled from "styled-components";
import {Collapse, Divider, Drawer, Input} from "antd";


export const MobileSearchFilterStyled = styled(Drawer)`
  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    padding: 16px 16px 24px;
    overflow-x: hidden;

    .header {
      display: flex;
      align-items: center;
      column-gap: 2px;
      margin-bottom: 16px;

      svg {
        color: #797979;
      }
    }

    .tablet-tags {
      padding: 16px;
      background: #F6F4FE;
    }

    .controls {
      display: flex;
      column-gap: 16px;
      justify-content: flex-end;
      margin-top: auto;
      padding: 30px 0 0;

      > button {
        border-radius: 8px;
        padding: 5px 15px;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;

        &.cancel {
          border: 1px solid #D9D9D9;
          background: #FFF;
          box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.02);
          color: rgba(0, 0, 0, 0.85);
        }

        &.search {
          border: 1px solid #18067D;
          background: #18067D;
          color: #FFF;
            width: 75%;
        }
      }
    }
  }

    .ant-drawer-close {
        position: absolute;
        right: 16px;
        left: auto;
        color: black;
        font-size: 26px;
        margin-right: 0;
    }
`;

export const FiltersWrapper=styled(Collapse)`

    .special-filter-item {
        padding: 22px 0;
        display: flex;
        align-items: center;
    }
    
    .city-filter-container {
        padding: 0;
        border-top: none;
        border-bottom: 1px solid #F0F0F0;
        padding-bottom: 8px;
    }

    .availability-filter {
        
    .ant-collapse-content-box {
        padding: 0;
        padding-bottom: 0 !important;
        margin-bottom: 0;
        }
    }
`

export const FilterItem=styled(Collapse.Panel)`

  >.ant-collapse-header {
    flex-direction: row-reverse;
    padding: 8px 16px !important;
    background: #FFF;
    box-shadow: 0px -1px 0px 0px #F0F0F0 inset;

    .ant-collapse-header-text {
      display: flex;
      align-items: center;
      column-gap: 8px;

      h2 {
        color: rgba(0, 0, 0, 0.85);
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin: 0;
      }
    }

    .ant-collapse-expand-icon {

      span {
        margin: 0 !important;

        svg {
          width: 16px;
          height: 16px;
          transform: rotate(90deg);
          color: #797979;
        }
      }
    }

    &[aria-expanded="true"] {

      .ant-collapse-expand-icon {
        span {
          svg {
            transform: rotate(-90deg) !important;
          }
        }
      }
    }
  }
    
        .ant-collapse-content-box {
            margin: 8px 0;
        }
`

export const StyledInput=styled(Input)`
  border: none;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

  &:focus {
    box-shadow: none;
  }

`

export const StyledDivider=styled(Divider)`
  margin: 0 -16px;
  width: 110%;
`