import React, {CSSProperties, FC, JSXElementConstructor, ReactElement} from "react";
import {Select} from "antd";
import {SizeType} from "antd/lib/config-provider/SizeContext";

import {DropdownComponentWrapper, StyledSelect} from "./dropdown.styled";


interface Props {
		options: Option[];
		setValue: any;
		value?: string;
		placeholder: string;
		disabled?: boolean;
		size?: SizeType;
		height?: "auto" | string;
		sortByAlphabet?: boolean;
		style?: CSSProperties;
		bordered?: boolean;
		showSearch?: boolean;
		dataAttr?: string;
		renderPopupInsideContainer?: boolean;
		open?: boolean;
		dropdownMatchSelectWidth?:boolean
		openDropdownToRightSide?: boolean;
		renderMenu?: (props: any) => ReactElement<any, string | JSXElementConstructor<any>>;
		onDropdownVisibleChange?: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface Option {
		value: number | string;
		name: string;
		code?: number | string;
		disabled?: boolean;
}

export const Dropdown: FC<Props> = ({
																				options,
																				setValue,
																				value,
																				placeholder,
																				disabled,
																				size = "large",
																				height = "38px",
																				sortByAlphabet = true,
																				style = {},
																				bordered = true,
																				showSearch = true,
																				dataAttr,
																				renderPopupInsideContainer = true,
																				open,
																				dropdownMatchSelectWidth,
																				openDropdownToRightSide = false,
																				renderMenu,
																				onDropdownVisibleChange,
																		}) => {
																			
																			
		return <DropdownComponentWrapper>
				<StyledSelect
					dropdownAlign={openDropdownToRightSide ? {points: ["tl", "tr"], offset: [-5, 10],} : undefined}
					showSearch={showSearch}
											dropdownMatchSelectWidth={dropdownMatchSelectWidth}
											style={style}
											disabled={disabled}
											bordered={bordered}
											open={open}
											placeholder={placeholder}
											optionFilterProp="children"
											onChange={setValue}
											value={value}
											size={size}
											height={height}
											data-cy={dataAttr}
											onDropdownVisibleChange={onDropdownVisibleChange}
											getPopupContainer={renderPopupInsideContainer ? (elem => elem) : undefined}
											filterOption={(input, option) => (option!.children).toLocaleLowerCase().includes(input.toLocaleLowerCase())}
											filterSort={sortByAlphabet ?
													(optionA, optionB) =>
															(optionA!.children)
																	.toLowerCase()
																	.localeCompare((optionB!.children).toLowerCase())
													: undefined
											}
											dropdownRender={renderMenu}
				>
						{options.map((option, index) => <Select.Option key={option.code || index}
																													 value={option.value}
																													 disabled={option.disabled}
																													 name={option.name}
																													 code={option.code}
						>
								{option.name}
						</Select.Option>)}

				</StyledSelect>

		</DropdownComponentWrapper>;
};
