import {ChangeEvent, FC, useState} from "react";
import classNames from "classnames";
import {SearchOutlined} from "@ant-design/icons";

import {Sidebar} from "../../sidebar";
import {Hint} from "../../hint";
import {Filter, TagsRemoveMethodsBindings} from "../../../pages";
import {MobileSearchFilter} from "../../../pages/mobile";
import {Authority, setState, StepIds} from "../../../../types";
import {Translations, TType} from "../../../../assets/translations";
import {StyledLogo, StyledMobileHeader, StyledOpenMenu, StyledUnderline} from "./mobile-header.styled";
import {LocaleAuthMenu} from "./mobile-header-menu";
import {AffixStyled} from "../../../layout-components/affix/affix-styled";


interface Props {
    t: TType;
    avatarId: string;
    logOut: any;
    firstName: string;
    lastName: string;
    notificationsCount: number;
    setNotificationsCount: setState<number>;
    messagesCount: number;
    setMessagesCount: setState<number>;
    language: Translations;
    onLanguageChange: (value: string) => void;
    goHome: () => void;
    signIn: () => void;
    openProfilePage: () => void;
    authority: Authority;
    isAuthenticated: () => boolean;
    searchDrawerOpen: boolean;
    setSearchDrawerOpen: any;
    filterData: Filter;
    setFilterData: setState<Filter>;
    searchStatus: boolean;
    tags: TagsRemoveMethodsBindings[];
    setTags: setState<TagsRemoveMethodsBindings[]>;
    performSearch: () => void;
    clearAll: () => void;
    applySearchText: (event: ChangeEvent<HTMLInputElement>) => void;
    menuOpen: boolean;
    handleMenuOpen: () => void;
    registrationStep: number | null;
}

export const MobileHeaderComponent: FC<Props> = ({
                                                     t,
                                                     avatarId,
                                                     logOut,
                                                     firstName,
                                                     lastName,
                                                     notificationsCount,
                                                     messagesCount,
                                                     language,
                                                     onLanguageChange,
                                                     goHome,
                                                     openProfilePage,
                                                     authority,
                                                     isAuthenticated,
                                                     searchDrawerOpen,
                                                     setSearchDrawerOpen,
                                                     filterData,
                                                     setFilterData,
                                                     searchStatus,
                                                     tags,
                                                     setTags,
                                                     performSearch,
                                                     clearAll,
                                                     applySearchText,
                                                     menuOpen,
                                                     handleMenuOpen,
                                                     registrationStep
                                                 }) => {

  const [localeAuthMenuOpen, setLocaleAuthMenuOpen] = useState<boolean>(false);

    const isTeacher = authority === Authority.ROLE_TEACHER;

    const openSearchDrawer = () => setSearchDrawerOpen(true);

    const disabledNavigation = registrationStep !== null;

    return <AffixStyled>
        <StyledMobileHeader>
              <div className="header-logo" data-cy="header-logo" onClick={() => !disabledNavigation && goHome()}>
                <StyledLogo/>
                <h4>{t("teaching-me")}
                  <StyledUnderline/>
                </h4>
              </div>

          <div className="search-button-and-menu-container">
            {!isTeacher &&
                <Hint showHint={disabledNavigation}
                      placement="bottom"
                      title={t("registration.disabled-header-hint")}>
                        <span>
                             <div data-cy="search-button-inside-mobile-header"
                                  className={classNames("search-button", {searchModeActive: searchStatus})}
                                  onClick={openSearchDrawer}>
                                <SearchOutlined/> {t("find-tutor")}
                            </div>
                         </span>
                </Hint>}

            {isAuthenticated() ? <StyledOpenMenu data-cy="burger-menu" id={StepIds.menu} onClick={handleMenuOpen}/>
              : <StyledOpenMenu data-cy="burger-menu" onClick={()=> setLocaleAuthMenuOpen(true)}/>}

          </div>

            {isAuthenticated() ?
                    <Sidebar t={t}
                             language={language}
                             onLanguageChange={onLanguageChange}
                             handleMenuOpen={handleMenuOpen}
                             menuOpen={menuOpen}
                             authority={authority}
                             firstName={firstName}
                             lastName={lastName}
                             avatarId={avatarId}
                             notificationsCount={notificationsCount}
                             messagesCount={messagesCount}
                             openProfilePage={openProfilePage}
                             logOut={logOut}/>

              :
            <LocaleAuthMenu language={language} onLanguageChange={onLanguageChange} t={t} open={localeAuthMenuOpen} setIsOpen={setLocaleAuthMenuOpen}/>

            }

          {!isTeacher && <MobileSearchFilter t={t}
                                setSearchDrawerOpen={setSearchDrawerOpen}
                                searchDrawerOpen={searchDrawerOpen}
                                tags={tags}
                                setTags={setTags}
                                clearAll={clearAll}
                                performSearch={performSearch}
                                filterData={filterData}
                                setFilterData={setFilterData}
                                applySearchText={applySearchText}
            />}
        </StyledMobileHeader>
    </AffixStyled>;
};