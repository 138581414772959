import React, {CSSProperties, FC, memo, useMemo} from "react";

import {Dropdown, Option} from "../dropdown";
import {Translations, TType} from "../../../assets/translations";
import {Wrapper} from "./language-switch.styled";


interface Props {
    t: TType;
    language: Translations;
    onLanguageChange: (value: string) => void;
    style?: CSSProperties;
    className?: string;
    openDropdownToRightSide?: boolean;
    renderPopupInsideContainer?: boolean;
}


export const LanguageSwitch: FC<Props> = memo(({t, language, onLanguageChange, style = {}, className = "", openDropdownToRightSide, renderPopupInsideContainer}) => {

    const options: Option[] = useMemo(() => [
        {
            value: Translations.uk,
            name: 'Укр'
        },
        {
            value: Translations.en,
            name: 'En'
        },
    ], []);


    return <Wrapper style={style} className={className}>
        <Dropdown
          showSearch={false}
                  dataAttr="lang-switch"
                  placeholder={t("language.placeholder")}
                  renderPopupInsideContainer={renderPopupInsideContainer || false}
                  value={language}
                  setValue={onLanguageChange}
                  bordered={false}
                  options={options}
                    openDropdownToRightSide={openDropdownToRightSide}/>
    </Wrapper>
});
