import {FC, ReactElement} from "react";
import {Navigate, useSearchParams} from "react-router-dom";
import {useSelector} from "react-redux";

import {Authority} from "../types";
import {RootState} from "../components/redux";


interface Props {
    children: ReactElement | null;
}

export const TeachersSecureRoute: FC<Props> = ({children}) => {

    const {jwtParsed: {authority}} = useSelector((state: RootState) => state.tokensStore);

    const {prevLessonDetailsForNextLesson} = useSelector((state: RootState) => state.userStore);
    const [searchParams] = useSearchParams();

    const code = searchParams.get("code");
    const email = searchParams.get("email");

    if (authority === Authority.ROLE_TEACHER && !prevLessonDetailsForNextLesson?.student.id && (!code || !email)) {
        return <Navigate to="/user/profile"/>;
    }

    return children;
}
