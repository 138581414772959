import {FC, useState} from 'react';
import {InputNumber, Slider} from "antd";
import {useMediaQuery} from "react-responsive";

import {Filter, TagsRemoveMethodsBindings} from "../../search-input-filter.types";
import {device} from "../../../../../constants";
import {setState, TPriceRange} from "../../../../../../types";
import {TType} from "../../../../../../assets/translations";
import {PriceFilterStyled, StyledHryvniaSignIcon} from "./price-filter.styled";
import {useSearchBarTabsUpdateState} from "../../../../../../context/SearchBarTabsContext";


interface Props {
    t: TType;
    filterData: Filter;
    setFilterData: setState<Filter>;
    setTags: setState<TagsRemoveMethodsBindings[]>;
    tagName?: string;
    storedPriceRange: TPriceRange;
}

export const PriceFilterComponent : FC<Props> = ({t, filterData, setFilterData, setTags, tagName = "priceTag", storedPriceRange}) => {

    const {minPrice:min, maxPrice: max} = storedPriceRange

    const isMobile = useMediaQuery({
        query: `(${device.mobileMax})`
    });

    const [minPrice, setMinPrice] = useState<number>(filterData.priceFrom || min);

    const [maxPrice, setMaxPrice] = useState<number>(filterData.priceTo || max);

    const searchBarTabsUpdateState = useSearchBarTabsUpdateState();

    const changeRange = (value: [number, number]) => {
        setMinPrice(value[0]);
        setMaxPrice(value[1]);

        isMobile && applyPrice(value[0], value[1]);
    };

    const changeMaxValue = (value: number | null) => {
        if (value! > max) {
            setMaxPrice(max);
        } else {
            if (value! < minPrice) {
                setMaxPrice(minPrice);
            } else {
                setMaxPrice(value!);
            }
        }
    };

    const changeMinValue = (value: number | null) => {
        if (value! < min) {
            setMinPrice(min);
        } else {
            if (value! > maxPrice) {
                setMinPrice(maxPrice);
            } else {
                setMinPrice(value!);
            }
        }
    };

    const resetPrice = () => {
        setTags(prev => prev.filter(tag => tag.id !== tagName));
        setFilterData(prev => ({...prev, priceFrom: undefined, priceTo: undefined}));
        searchBarTabsUpdateState && searchBarTabsUpdateState(prev=>({...prev, prices: {...prev.prices, value: false}}));
        setMinPrice(min);
        setMaxPrice(max);
    };

    const applyPrice = (minPriceValue = minPrice, maxPriceValue = maxPrice) => {
        searchBarTabsUpdateState && searchBarTabsUpdateState(prev => ({...prev, prices: {status: false, value: true}}))

        setTags(prev => {
            const tag = prev.find(tag => tag.id === tagName);

            const title = `₴${minPriceValue} - ₴${maxPriceValue}`;

            if (tag) {
                return [...prev.map(tag => tag.id === tagName ? {
                    ...tag,
                    title,
                    removeTagMethod: resetPrice
                } : {...tag})];
            } else {
                return [...prev, {id: tagName, title, removeTagMethod: resetPrice}];
            }
        })


        setFilterData(prev => ({...prev, priceFrom: minPriceValue, priceTo: maxPriceValue}));
    };


    return <PriceFilterStyled>
        {!isMobile && <h2>{t("searchpage.filters.prices.modal-title")}</h2>}
        <div className="slider">
            <div className="values">
                <p>{min}</p>
                <p>{max}</p>
            </div>
            <Slider range={{draggableTrack: true}}
                    value={[minPrice, maxPrice]}
                    step={0.1}
                    min={min}
                    max={max}
                    onChange={changeRange}
            />
        </div>

        {!isMobile && <>
            <div className="range-inputs">
                <div className="range-input">
                    <p className="range-input-title">{t("searchpage.filters.min")}</p>
                    <InputNumber step={0.1}
                                 precision={2}
                                 addonAfter={<StyledHryvniaSignIcon/>}
                                 value={minPrice}
                                 onChange={changeMinValue}
                    />

                </div>

                <div className="range-input">
                    <p className="range-input-title">{t("searchpage.filters.max")}</p>
                    <InputNumber step={0.1}
                                 precision={2}
                                 addonAfter={<StyledHryvniaSignIcon/>}
                                 value={maxPrice}
                                 onChange={changeMaxValue}
                    />

                </div>
            </div>

            <div className="controls">
                <button data-cy="price-filter-reset-button" className="reset" onClick={resetPrice}>{t("searchpage.reset")}</button>
                <button data-cy="price-filter-apply-button" className="apply" onClick={() => applyPrice()}>{t("searchpage.apply")}</button>
            </div>
        </>}
    </PriceFilterStyled>
};
