import {FC} from "react";
import {Checkbox} from "antd";
import {CheckboxChangeEvent} from "antd/es/checkbox";

import {setState} from "../../../../../../types";
import {Filter} from "../../search-input-filter.types";
import {TType} from "../../../../../../assets/translations";

interface Props {
  setFilterData: setState<Filter>;
  t: TType;
}

export const VerificationFilter: FC<Props> = ({t, setFilterData}) => {

  function handleOnChange(e: CheckboxChangeEvent) {
    const statusValue = e.target.checked;

    setFilterData(prev => ({...prev, verifiedTeacher: statusValue || undefined}));
  }

  return <Checkbox onChange={handleOnChange}>{t("searchpage.filters.status.title")}</Checkbox>
};