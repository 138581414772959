import {createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useState} from "react";

type ISearchBarTabState = {
  status: boolean;
  value: boolean;
}

export interface ISearchBarTabsStateContext {
  skills: ISearchBarTabState,
  status: ISearchBarTabState,
  prices: ISearchBarTabState
}

const defaultSearchBarTabsState = {
  skills: {
    status: false,
    value: false
  },
  status: {
    status: false,
    value: false
  },
  prices: {
    status: false,
    value: false
  }
}

const SearchBarTabsContext = createContext<ISearchBarTabsStateContext>(defaultSearchBarTabsState);
const SearchBarTabsUpdateContext = createContext<Dispatch<SetStateAction<ISearchBarTabsStateContext>> | null>(null);

export function useSearchBarTabsState() {
  return useContext(SearchBarTabsContext);
}

export function useSearchBarTabsUpdateState() {
  return useContext(SearchBarTabsUpdateContext);
}

interface Props {
  children: ReactNode;
}

export const SearchBarTabsProvider:FC<Props> = ({children}) => {

  const [searchBarTabs, setSearchBarTabs] = useState<ISearchBarTabsStateContext>(defaultSearchBarTabsState);

  return (
    <SearchBarTabsContext.Provider value={searchBarTabs}>
      <SearchBarTabsUpdateContext.Provider value={setSearchBarTabs}>
        {children}
      </SearchBarTabsUpdateContext.Provider>
    </SearchBarTabsContext.Provider>
  );
}