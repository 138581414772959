import {FC, useEffect, useState,} from "react";

import {initAnalytics} from "../../../config";
import cookieService, {cookieConsentKey} from "../../../services/cookie-service";
import {TType} from "../../../assets/translations";
import {CloseButton, Consent, Cookie} from "./cookie-consent.styled";
import {today} from "../../../services/date-service";


interface Props {
    t: TType;
}

export const CookieConsentModal: FC<Props> = ({t}) => {

    const [showConsent, setShowConsent] = useState<boolean>(false);

    const setConsentCookie = (value: string) => {
        const cookieExpDate = new Date(today);
        cookieExpDate.setFullYear(today.getFullYear() + 1);

        cookieService.set(cookieConsentKey, value, {
            path: "/",
            sameSite: "lax",
            expires: cookieExpDate
        });
    }

    const handleAcceptCookie = (resetCookie = false) => {
        if (resetCookie) {
            setConsentCookie("true");
        }

        initAnalytics();
    };

    const handleDeclineCookie = (resetCookie = false) => {
        if (resetCookie) {
            setConsentCookie("false");
        }

        cookieService.remove("_ga");
        cookieService.remove("_gat");
        cookieService.remove("_gid");
    };

    useEffect(() => {
        const cookieValue = cookieService.get(cookieConsentKey);

        setShowConsent(!cookieValue);

        if (cookieValue === "true") {
            handleAcceptCookie();
        } else {
            handleDeclineCookie();
        }
    }, []);

    const acceptCookieandCloseModal = () => {
        handleAcceptCookie(true);
        setShowConsent(false);
    }

    if (!showConsent) {
        return null;
    }

    return <Consent>
        <div className="text">
            <Cookie/>
            <h2>{t("cookie-consent.title")}</h2>
        </div>
        <div className="buttons">
            <button className="decline" data-cy="cookie-decline" onClick={() => acceptCookieandCloseModal()}>
                {t("cookie-consent.decline")}
            </button>
            <button className="agree" onClick={() => acceptCookieandCloseModal()}>
                {t("cookie-consent.agree")}
            </button>
        </div>
        <CloseButton className="close" onClick={() => acceptCookieandCloseModal()}><div className="icon"/></CloseButton>
    </Consent>
};