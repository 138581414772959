import {ChangeEvent, FC, useEffect, useMemo, useState} from "react";
import {useDispatch} from "react-redux";

import {
    AvailabilityFilter,
    PriceFilter,
    SkillsFilter
} from "../../../home-page/search-filter/filters";
import {Filter, RadioData, TagsRemoveMethodsBindings} from "../../../home-page";
import {SearchTags} from "../../../home-page/search-filter/search-tags";
import {SET_SEARCH_BUTTON_CLICKED} from "../../../../redux";
import {useDisableScroll} from "../../../../../hooks";
import {Country, setState} from "../../../../../types";
import {TType} from "../../../../../assets/translations";
import {
    FilterItem,
    FiltersWrapper,
    MobileSearchFilterStyled
} from "./mobile-search-filter.styled";
import {SkillsIcon} from "../../../../../assets/images/svg-elements/skills";
import {WifiIcon} from "../../../../../assets/images/svg-elements/wifi";
import {VerificationFilter} from "../../../home-page/search-filter/filters/verification-filter";


interface Props {
    t: TType;
    searchDrawerOpen: boolean;
    setSearchDrawerOpen: setState<boolean>;
    tags: TagsRemoveMethodsBindings[];
    setTags: setState<TagsRemoveMethodsBindings[]>;
    performSearch: () => void;
    clearAll: () => void;
    applySearchText: (event: ChangeEvent<HTMLInputElement>) => void;
    filterData: Filter;
    setFilterData: setState<Filter>;
    storedCountries: Country[];
}

export const MobileSearchFilterComponent: FC<Props> = ({
                                                           t,
                                                           searchDrawerOpen,
                                                           setSearchDrawerOpen,
                                                           tags,
                                                           setTags,
                                                           clearAll,
                                                           performSearch,
                                                           filterData,
                                                           setFilterData,
                                                           storedCountries
                                                       }) => {

    const dispatch = useDispatch();

    useDisableScroll(searchDrawerOpen);

    const search = () => {
        dispatch({type: SET_SEARCH_BUTTON_CLICKED, searchButtonClicked: true});

        performSearch();
        setSearchDrawerOpen(false);
    };

    const [countries, setCountries] = useState<RadioData[]>([]);

    const memoFilters = useMemo(() => [
        {
            panelKey: "1",
            title: t("searchpage.filters.skills.title"),
            icon: <SkillsIcon/>,
            content: <SkillsFilter setFilterData={setFilterData}
                                   setTags={setTags}/>
        },
        {
            panelKey: "2",
            title: t("searchpage.filters.prices.title"),
            icon: <SkillsIcon/>,
            content: <PriceFilter t={t}
                                  filterData={filterData}
                                  setFilterData={setFilterData}
                                  setTags={setTags}/>
        },
        {
            panelKey: "3",
            title: t("searchpage.filters.availability.title"),
            icon: <WifiIcon/>,
            content: <AvailabilityFilter t={t}
                                         filterData={filterData}
                                         setFilterData={setFilterData}
                                         setTags={setTags}/>
        }
    ], [countries]);

    useEffect(() => {
        if (storedCountries.length) {
            const countriesRadioData = storedCountries.map((country: Country) => ({
                value: country,
                name: country.name
            }));

            setCountries(countriesRadioData);
        }
    }, [storedCountries]);


    return <MobileSearchFilterStyled placement="top"
                                     closable={true}
                                     height="100%"
                                     onClose={() => setSearchDrawerOpen(false)}
                                     open={searchDrawerOpen}
                                     title={"Find Teacher:"}
    >
        {!!tags.length && <div data-cy="tablet-tags-mobile" className="tablet-tags">
            <SearchTags t={t} tags={tags} clearAll={clearAll}/>
        </div>}
        <FiltersWrapper ghost accordion defaultActiveKey={["1"]}>
            {memoFilters.map(({title, icon, content, panelKey}) =>
              <FilterItem data-cy="filter-item" className={panelKey === '3' ? "availability-filter" : ""} key={panelKey}
                          header={<>{icon}<h2>{title}</h2></>}
              >
                  {content}
              </FilterItem>
            )}
            <span className="special-filter-item">
                <VerificationFilter t={t} setFilterData={setFilterData}/>
            </span>
        </FiltersWrapper>
        <div className="controls">
            <button className="cancel" onClick={() => setSearchDrawerOpen(false)}>{t("searchpage.cancel")}</button>
            <button data-cy='mobile-filter-search-button' className="search"
                    onClick={search}>{t("searchpage.search")}</button>
        </div>
    </MobileSearchFilterStyled>;
};
