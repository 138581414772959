import {FC, useCallback} from "react";
import {Badge, Divider} from "antd";
import {NavLink} from "react-router-dom";
import {CloseOutlined} from "@ant-design/icons";
import classNames from "classnames";

import {Hint} from "../hint";
import {useDisableScroll} from "../../../hooks";
import {Authority, StepIds} from "../../../types";
import {Translations, TType} from "../../../assets/translations";
import {StyledAvatarImg, StyledDrawer} from "./sidebar.styled";
import {FavouritesIcon} from "../../../assets/images/svg-elements/favourites";
import {ScheduleIcon} from "../../../assets/images/svg-elements/schedule";
import {LessonsIcon} from "../../../assets/images/svg-elements/lessons";
import {BellIcon} from "../../../assets/images/svg-elements/bell";
import {SmsIcon} from "../../../assets/images/svg-elements/sms";
import {SupportIcon} from "../../../assets/images/svg-elements/support";
import {LogoutIcon} from "../../../assets/images/svg-elements/logout";
import {SettingsIcon} from "../../../assets/images/svg-elements/settings";
import {getUsersPicture} from "../../../services/user-service";
import {LanguageSwitch} from "../language-switch";
import {useMediaQuery} from "react-responsive";
import {device} from "../../constants";


interface Props {
    t: TType;
    language?: Translations;
    onLanguageChange?: (value: string) => void;
    authority: Authority;
    firstName: string;
    lastName: string;
    avatarId: string;
    openProfilePage: () => void;
    logOut: () => void;
    notificationsCount: number;
    messagesCount: number;
    menuOpen: boolean;
    handleMenuOpen: () => void;
    registrationStep: number | null;
}

export const SidebarComponent: FC<Props> = ({
                                                t,
                                                language,
                                                onLanguageChange,
                                                authority,
                                                firstName,
                                                lastName,
                                                avatarId,
                                                openProfilePage,
                                                logOut,
                                                notificationsCount,
                                                messagesCount,
                                                menuOpen,
                                                handleMenuOpen,
                                                registrationStep,
                                            }) => {

    useDisableScroll(menuOpen);

    const navigateAndCloseMenu = useCallback((cb?: () => void) => () => {
        cb && cb();

        handleMenuOpen();
    }, [handleMenuOpen]);

    const isMobile = useMediaQuery({
        query: `(${device.mobileMax})`
    });

    const disabledNavigation = registrationStep !== null;


    return <StyledDrawer closable={false}
                         onClose={handleMenuOpen}
                         open={menuOpen}
                         width="300px">

        <div className="main-info" data-cy="drawer-main-info">
            <CloseOutlined data-cy="close-burger-menu" className="close" onClick={handleMenuOpen}/>

            <div className="profile">
                <StyledAvatarImg src={getUsersPicture(avatarId)} loading="lazy" alt={`${firstName} ${lastName}`}/>

                <div className="name">
                    <p data-cy="mobile-profile-name">{firstName} {lastName}</p>

                    <Hint showHint={disabledNavigation}
                          placement="bottom"
                          title={t("registration.disabled-header-hint")}>
                        <span>
                            <p className={classNames("view-profile", {disabled: disabledNavigation})}
                               data-cy="view-profile"
                               onClick={navigateAndCloseMenu(openProfilePage)}>
                                {t("profile.view")}
                            </p>
                        </span>
                    </Hint>


                </div>
            </div>
        </div>

        <Divider style={{margin: 0}}/>

        <div className="navigation">
            {authority === Authority.ROLE_STUDENT &&

                <Hint showHint={disabledNavigation}
                      placement="bottom"
                      title={t("registration.disabled-header-hint")}>
                        <span>
                            <NavLink id={StepIds.favourites}
                                     className={classNames("menu-button", {disabled: disabledNavigation})}
                                     data-cy="my-favourites"
                                     to="/favourites"
                                     onClick={navigateAndCloseMenu()}>
                                <FavouritesIcon/>{t("favourites.header.caption")}
                            </NavLink>
                        </span>
                </Hint>}

            {authority === Authority.ROLE_TEACHER &&
                <Hint showHint={disabledNavigation}
                      placement="bottom"
                      title={t("registration.disabled-header-hint")}>
                        <span>
                            <NavLink id={StepIds.schedule}
                                     className={classNames("menu-button", {disabled: disabledNavigation})}
                                     data-cy="menu-button"
                                     to="/schedule"
                                     onClick={navigateAndCloseMenu()}>
                                <ScheduleIcon/>{t("schedule.header")}
                            </NavLink>
                        </span>
                </Hint>}

            <Hint showHint={disabledNavigation}
                  placement="bottom"
                  title={t("registration.disabled-header-hint")}>
                        <span>
                            <NavLink id={StepIds.lessons}
                                     className={classNames("menu-button", {disabled: disabledNavigation})}
                                     data-cy="menu-button"
                                     data-cypress="my-lessons"
                                     to="/lessons"
                                     onClick={navigateAndCloseMenu()}>
                                <LessonsIcon/>{t("lessons.header.title")}
                            </NavLink>
                        </span>
            </Hint>

            <Divider style={{width: "200%", margin: "16px 0 16px -50%"}}/>

            <Badge count={notificationsCount} size="default" offset={[-40, 32]}>
                <Hint showHint={disabledNavigation}
                      placement="bottom"
                      title={t("registration.disabled-header-hint")}>
                        <span>
                            <NavLink id={StepIds.notifications}
                                     className={classNames("menu-button", {disabled: disabledNavigation})}
                                     data-cy="menu-button"
                                     data-cypress="sidebar-my-notifications"
                                     to="/notifications"
                                     onClick={navigateAndCloseMenu()}>
                                <BellIcon/>{t("notifications.title")}
                            </NavLink>
                        </span>
                </Hint>
            </Badge>

            <Badge count={messagesCount} size="default" offset={[-40, 32]}>
                <Hint showHint={disabledNavigation}
                      placement="bottom"
                      title={t("registration.disabled-header-hint")}>
                        <span>
                            <NavLink id={StepIds.messenger}
                                     className={classNames("menu-button", {disabled: disabledNavigation})}
                                     data-cy="mobile-messenger-icon"
                                     to="/m"
                                     onClick={navigateAndCloseMenu()}>
                            <SmsIcon/>{t("messenger.title")}
                          </NavLink>
                        </span>
                </Hint>
            </Badge>

            <Hint showHint={disabledNavigation}
                  placement="bottom"
                  title={t("registration.disabled-header-hint")}>
                        <span>
                            <NavLink id={StepIds.settings}
                                     className={classNames("menu-button", {disabled: disabledNavigation})}
                                     data-cy="menu-button"
                                     to="/user/settings"
                                     onClick={navigateAndCloseMenu()}>
                                <SettingsIcon/>{t("settings")}
                            </NavLink>
                        </span>
            </Hint>

            <Hint showHint={disabledNavigation}
                  placement="bottom"
                  title={t("registration.disabled-header-hint")}>
                        <span>
                            <NavLink id={StepIds.support}
                                     className={classNames("menu-button", {disabled: disabledNavigation})}
                                     data-cy="menu-button"
                                     to="/support"
                                     onClick={navigateAndCloseMenu()}>
                                <SupportIcon/>{t("support.title")}
                            </NavLink>
                        </span>
            </Hint>

            {isMobile && language && onLanguageChange && <LanguageSwitch openDropdownToRightSide={true}
                                                                         className="language-switch" t={t}
                                                                         language={language}
                                                                         onLanguageChange={onLanguageChange}/>}

            <div className="logout" data-cy="logout-button" onClick={logOut}>
                {t("login.form.button.signout")}
                <LogoutIcon/>
            </div>
        </div>
    </StyledDrawer>;
};
