import {Drawer} from "antd";
import styled from "styled-components";

export const LocaleAuthMenuStyled = styled(Drawer)`


    .ant-drawer-header{
        padding: 27px 0;
    }

    .ant-drawer-close {
        position: absolute;
        right: 16px;
        left: auto;
        color: black;
        font-size: 26px;
        margin-right: 0;
    }
    
     .ant-drawer-body {
         display: flex;
         row-gap: 24px;
         flex-direction: column;
     }
    
    .not-authorized-buttons-wrapper {
        display: flex;
        flex-direction: column;
        column-gap: 24px;
        row-gap: 12px;

        .login, .create-account {
            display: flex;
            padding: 7px 16px;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            font-weight: 400;
            border-radius: 8px;
            line-height: 24px;
            cursor: pointer;
            transition: hover, 0.4s;
        }

        .login {
            column-gap: 16px;
            border: 1px solid #D9D9D9;
            background: #FFF;

            &:hover {
                border-color: #000;
            }
        }

        .create-account {
            color: #fff;
            border: 1px solid #18067D;
            background: #18067D;

            &:hover {
                background: #000;
                border-color: #000;
            }
        }
    }

    .language-switch-container {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        height: 100%;
        
        
        >div {
            margin-right: 0;
        }
    }
`