import styled from 'styled-components';


export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
    margin-right: 9px;

  > p {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
`
