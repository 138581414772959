import styled from 'styled-components';
import {Drawer} from "antd";

import {size} from "../../constants";


export const StyledDrawer = styled(Drawer)`
  z-index: 98;
    
  .ant-drawer-content-wrapper {
    box-shadow: none;
  }


    .language-switch {
        
        .ant-select-selector {
            padding-left: 14px !important;
        }
        
      .ant-select-selection-item {
          color: rgb(115, 108, 148);
          font-size: 16px;
          font-weight: 400;
      }
    
      .ant-select-arrow {
          color: rgb(115, 108, 148);
          font-size: 14px;
          font-weight: 400;
      }
    }
    
  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    padding: 0;
    overflow-x: hidden;

    .main-info {
      padding: 36px 20px 12px;
      display: flex;
      flex-direction: column;
      row-gap: 24px;

      .profile {
        display: flex;
        column-gap: 12px;
        align-items: center;

        .name {
          padding-left: 8px;
          border-left: 1px solid lightgray;

          p {
            margin: 0;
            font-size: 18px;
          }

          .view-profile {
            color: #5D51A4;
            cursor: pointer;
          }
        }
      }

      .close {
        position: absolute;
        right: 20px;
        top: 20px;
      }
    }

    .navigation {
      padding: 32px 20px;
      display: flex;
      flex-direction: column;
      height: 100%;

      .menu-button {
        padding: 20px 14px;
        display: flex;
        align-items: center;
        column-gap: 8px;
        color: #736C94;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.16px;
        border-radius: 8px;
        transition: all 300ms ease-in-out;

        svg {
          width: 20px;
          height: 20px;
        }

        &.active {
          color: #fff;
          background: #0E044B;
          pointer-events: none;
        }
      }

      .ant-badge {
        .ant-badge-count {
          background: rgb(115, 108, 148);
        }

        &:has(.menu-button.active) {
          .ant-badge-count {
            background: #fff;
            color: rgb(14, 4, 75);
          }
        }
      }

      .logout {
        cursor: pointer;
        margin-top: auto;
        padding: 16px 20px;
        border-radius: 8px;
        background: rgba(0, 0, 0, 0.05);
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #FF4949;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.16px;
      }
    }

  }

  .ant-drawer-content {
    border-radius: 5px 0 0 5px;
    background: #FFF;
    box-shadow: 0 2.76726px 2.21381px 0 rgba(0, 0, 0, 0.07), 0 6.6501px 5.32008px 0 rgba(141, 145, 160, 0.05), 0 12.52155px 10.01724px 0 rgba(141, 145, 160, 0.04), 0 22.33631px 17.86905px 0 rgba(141, 145, 160, 0.04), 0 41.77761px 33.42209px 0 rgba(141, 145, 160, 0.03), 0 100px 80px 0 rgba(141, 145, 160, 0.02);
  }

  span:has(.disabled) {
    cursor: not-allowed;

    .disabled {
      pointer-events: none;
    }
  }

  @media (${`max-width: ${size.mobileMin}`}) {
    .ant-drawer-content-wrapper {
      width: 100vw !important;
    }
  }
`

export const StyledAvatarImg = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`