import styled from "styled-components";
import {Layout} from "antd";
import {MenuOutlined} from "@ant-design/icons";
import {LogoIcon} from "../../../../assets/images/svg-elements/logo";


const {Header} = Layout;

export const StyledTabletHeader = styled(Header)`
  width: 100%;
    height: 54px;
  background-color: #FAFAFA;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 24px;

  .header-logo {
    display: flex;
    column-gap: 8px;
    align-items: center;
    cursor: pointer;

    h4 {
      margin: 0;
      display: flex;
      flex-direction: column;
      color: #18067D;
        font-size: 18px;
      font-weight: 400;
        line-height: 27px;
        text-align: center;
    }
  }

  .menu {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

    .not-authorized-buttons-wrapper {
        display: flex;
        column-gap: 24px;

        .login, .create-account {
            display: flex;
            padding: 7px 16px;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            font-weight: 400;
            border-radius: 8px;
            line-height: 24px;
            cursor: pointer;
            transition: hover, 0.4s;
        }

        .login {
            column-gap: 16px;
            border: 1px solid #D9D9D9;
            background: #FFF;

            &:hover {
                border-color: #000;
            }
        }

        .create-account {
            color: #fff;
            border: 1px solid #18067D;
            background: #18067D;

            &:hover {
                background: #000;
                border-color: #000;
            }
        }
    }

`;


export const StyledLogo = styled(LogoIcon)`
  height: 40px;
  width: 36px;
`

export const StyledOpenMenu = styled(MenuOutlined)`
  cursor: pointer;
`