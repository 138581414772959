import styled from "styled-components";
import {Layout} from "antd";
import {MenuOutlined} from "@ant-design/icons";
import {LogoIcon} from "../../../../assets/images/svg-elements/logo";
import {Underline} from "../../../../assets/images/svg-elements/underline";


const {Header} = Layout;

export const StyledMobileHeader = styled(Header)`
  width: 100%;
    height: 63px;
  background-color: @layout-body-background;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
  padding: 12px 14px;

    .header-logo {
        display: flex;
        column-gap: 2px;
        align-items: center;
        cursor: pointer;

        h4 {
            margin: 0;
            display: flex;
            flex-direction: column;
            color: #18067D;
            font-weight: 400;
            text-align: center;
            font-size: 13px;
            line-height: 19px;
        }
    }

    .search-button-and-menu-container {
        display: flex;
        column-gap: 12px;
        align-items: center;

        .search-button {
            border-radius: 8px;
            background: #2F1F8A;
            display: flex;
            padding: 7px 16px;
            align-items: center;
            column-gap: 8px;
            color: #FAFAFA;
            font-weight: 400;
            cursor: pointer;
            font-size: 14px;
            line-height: 24px;
            height: 40px;
            gap: 4px;
            
            span {
                font-size: 14px;
            }
        }
    }

  .menu {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  .login-button {
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    border-radius: 36px;
    border: 1px solid #D9D9D9;
    background: #FFF;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.02);
    padding: 4px 12px;
    transition: hover, 0.4s;
    cursor: pointer;

    &:hover {
      border-color: #000;
    }
  }

    @media (max-width: 385px) {
        .header-logo {
            h4 {
            display: none;
            }
        }
    }
`;


export const StyledOpenMenu = styled(MenuOutlined)`
  cursor: pointer;
    font-size: 28px;
`

export const StyledLogo = styled(LogoIcon)`
  height: 28px;
  width: 26px;
`

export const StyledUnderline = styled(Underline)`
    width: 85px;
`